import React from 'react';
import CallToAction from '@/Framework/UI/Pages/Landing/Sections/CallToAction';
import StartActions from '../StartActions';

const ReadyToStart = () => (
  <CallToAction
    dataTest="readyToStartSection"
    title="Ready to get started?"
    description="Schedule a demo with a product specialist who can answer any questions and show you the platform."
    actions={ <StartActions /> }
  />
);

export default ReadyToStart;
