import React from 'react';
import cn from 'classnames';
import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModal/BookDemoButton';
import baseStyles from '@/dataroom/ui/components/Landing/researchRoom/tenantResearchRoomStyles.scss';
import styles from './startActions.scss';

const StartActions = () => (
  <div className={ styles.actionsWrp }>
    <BookDemoButton className={ cn(baseStyles.actionButton, styles.bookDemoButton) } />
  </div>
);

export default StartActions;
