import React from 'react';
import SectionHeader from '@/Framework/UI/Pages/Landing/Sections/SectionHeader';
import Animation from '@/Framework/UI/Pages/Landing/Sections/Animation';
import Section from '../Section';
import styles from './callToAction.scss';
import SectionContainer from '../SectionContainer';

const params = {
  factor: 0.02,
  variation: 0.0017,
  amplitude: 177.2,
  lines: 24,
  waveColor: { r: 205, g: 205, b: 205, a: 0.3 },
  lineStroke: 1,
  speed: 0.0005,
};

interface IProps {
  title: React.ReactNode,
  description?: React.ReactNode,
  dataTest?: string,
  actions?: React.ReactNode,
}

const CallToAction = ({ dataTest, title, description, actions }: IProps) => {
  return (
    <Section className={ styles.section } dataTest={ dataTest }>
      <Animation parameters={ params } />
      <SectionContainer>
        <SectionHeader
          title={ title }
          description={ description }
        />
        <div className={ styles.actionsWrapper }>
          { actions }
        </div>
      </SectionContainer>
    </Section>
  );
};

export default CallToAction;
