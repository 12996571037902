import React, { useRef, useEffect, useCallback } from 'react';
import { createNoise3D } from 'simplex-noise';
import styles from './animation.scss';
import throttle from 'lodash/throttle';

const CANVAS_ID = 'webgl';

export const DEFAULT_PARAMS = {
  factor: 0.02,
  variation: 0.0017,
  amplitude: 150,
  lines: 24,
  waveColor: { r: 255, g: 255, b: 255, a: 0.1 },
  lineStroke: 1,
  speed: 0.0005,
};

const noise3D = createNoise3D();

const setupRandomness = (lines, factor) => {
  let newRandomness = [];

  for (let i = 0, rand = 0; i < lines; i++, rand += factor) {
    newRandomness[i] = rand;
  }

  return newRandomness;
};

const Animation = ({ parameters = DEFAULT_PARAMS }) => {
  const context = useRef(null);
  const requestIdRef = useRef<number>(null);
  const randomness = useRef([]);
  const container = useRef(null);
  const dimensions = useRef({ height: 0, width: 0 });

  const getSizes = () => {
    const pixelRatio = Math.min(window.devicePixelRatio, 0.9);

    container.current.width = dimensions.current.width * pixelRatio;
    container.current.height = dimensions.current.height * pixelRatio;
    context.current.scale(pixelRatio, pixelRatio);
  };

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = container.current?.getBoundingClientRect() || {};
      if (width && height) {
        dimensions.current = { width, height };
        getSizes();
      }
    };

    window.addEventListener('resize', throttle(handleResize, 100));
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const drow = useCallback(() => {
    context.current.lineWidth = parameters.lineStroke;

    let newRandomness = [];

    for (let i = 0; i < parameters.lines; i++) {
      context.current.beginPath();
      context.current.moveTo(0, dimensions.current.height / 2);

      let randomY = 0;
      for (let x = 0; x <= dimensions.current.width; x++) {
        randomY = noise3D(x * parameters.variation + randomness.current[i], x * parameters.variation, 1);
        context.current.lineTo(
          x, dimensions.current.height / 2 + (parameters.amplitude + Math.random() * 0.005) * randomY,
        );
      }

      const alpha = Math.min(Math.abs(randomY) + 0.001, parameters.waveColor.a);

      context.current.strokeStyle = `rgba(${ parameters.waveColor.r }, ${ parameters.waveColor.g }, ${ parameters.waveColor.b },${ alpha * 2 })`;
      context.current.stroke();
      context.current.closePath();

      newRandomness[i] = randomness.current[i] + parameters.speed;
    }

    randomness.current = newRandomness;
  }, []);

  const renderAnimation = () => {
    if (!container.current) return;
    context.current.clearRect(0, 0, window.innerWidth, dimensions.current.height);
    drow();

    requestIdRef.current = window.requestAnimationFrame(renderAnimation);
  };

  useEffect(() => {
    const { width, height } = container.current?.getBoundingClientRect() || {};
    context.current = container.current?.getContext('2d');

    dimensions.current = { width, height };
    randomness.current = setupRandomness(parameters.lines, parameters.factor);

    getSizes();

    requestIdRef.current = window.requestAnimationFrame(renderAnimation);

    return () => {
      cancelAnimationFrame(requestIdRef.current);
    };
  }, []);

  return (
    <canvas
      id={ CANVAS_ID }
      ref={ container }
      className={ styles.canvas }
    />
  );
};

export default Animation;
