import React from 'react';
import cn from 'classnames';
import styles from './sectionHeader.scss';

interface IProps {
  title: React.ReactNode,
  description?: React.ReactNode,
  maxWidth?: number,
  className?: string,
}

const SectionHeader = ({ title, description, maxWidth, className }: IProps) => (
  <div className={ cn(styles.wrapper, className) }>
    <div className={ styles.title }>{ title }</div>
    <div className={ styles.description } style={ { maxWidth } }>
      { description }
    </div>
  </div>
);

export default SectionHeader;
