interface IFeatureToggleServerSideOptions {
  featureCallback?: (context, params) => Promise<any>,
  featureName: string,
  featureCallbackParams?: {
    [key: string]: any,
  },
  defaultCallback?: (context, params) => Promise<any>,
  defaultCallbackParams?: {
    [key: string]: any,
  },
}
const featureToggleServerSide = async (context, {
  featureCallback,
  featureName,
  featureCallbackParams,
  defaultCallback,
  defaultCallbackParams,
}: IFeatureToggleServerSideOptions) => {
  const isFeatureEnabled = context.globalProps.featuresConfig[featureName].isEnabled;
  if (isFeatureEnabled) {
    if (!featureCallback) {
      return null;
    }
    return featureCallback(context, featureCallbackParams);
  }
  if (defaultCallback) {
    return defaultCallback(context, defaultCallbackParams);
  }
  return null;
};

export default featureToggleServerSide;
