import React from 'react';
import cn from 'classnames';
import styles from './section.scss';

interface IProps {
  children?: React.ReactNode,
  className?: string,
  dataTest?: string,
  padding?: 'both' | 'top' | 'bottom' | 'none',
  id?: string,
}

const Section = ({ children, className, padding = 'both', dataTest, id }: IProps) => (
  <div
    className={ cn(className, styles.section, {
      [styles.paddingTop]: padding === 'both' || padding === 'top',
      [styles.paddingBottom]: padding === 'both' || padding === 'bottom',
    }) }
    data-test={ dataTest }
    id={ id }
  >
    { children }
  </div>
);

export default Section;
