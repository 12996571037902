import React from 'react';
import { useFeatureToggleContext } from '../Context';

interface IProps {
  children?: React.ReactNode,
  featureName: string,
  defaultComponent?: React.ReactNode | React.JSX.Element,
}

const FeatureToggle = ({
 children,
 featureName,
 defaultComponent,
}: IProps) => {
  const { featuresConfig } = useFeatureToggleContext();
  const { isEnabled } = featuresConfig[featureName] || { isEnabled: false };
  if (isEnabled) {
    return <>{ children }</>;
  }

  return defaultComponent ? <>{ defaultComponent }</> : null;
};

export default FeatureToggle;
